// BackgroundMovement.js
import { useEffect } from 'react';

function BackgroundMovement() {

    useEffect(() => {
        const handleMouseMove = (e) => {
            const moveX = (e.clientX * -1 / 10);
            const moveY = (e.clientY * -1 / 10);
            document.body.style.backgroundPosition = moveX + 'px ' + moveY + 'px';
        };

        document.addEventListener('mousemove', handleMouseMove);

        // Čišćenje (uklanjanje) listenera kada se komponenta demontira
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return null; // Ova komponenta ne renderira ništa na ekran, samo dodaje event listener
}

export default BackgroundMovement;
