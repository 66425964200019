import React from 'react';
//import 'bootstrap/dist/css/bootstrap.min.css'; // Uključivanje Bootstrap CSS-a
import EscrowForm from './EscrowForm';
import Funds from './Funds';
import TransactionsList from './TransactionsList';

const Dashboard = () => {
  return (
    <div className="container mt-4"> 
      <div className="row"> 
        <div className="col-12 mb-3">
          <EscrowForm />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
