import React, { useState } from 'react';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const hardcodedUsername = 'rux';
  const hardcodedPassword = 'freak';

  const handleSubmit = (e) => {
    e.preventDefault();

    if (username === hardcodedUsername && password === hardcodedPassword) {
      onLogin();
    } else {
      alert('Pogrešno korisničko ime ili lozinka!');
    }
  };

  return (
    <div className="login-container">
      <h2 className="login-title">Prijava</h2>
      <form className="login-form" onSubmit={handleSubmit}>
        <div className="login-input-group">
          <label className="login-label">
            Korisničko ime:
            <input 
               type="text" 
               value={username} 
               onChange={(e) => setUsername(e.target.value)} 
               className="login-input"
            />
          </label>
        </div>
        <div className="login-input-group">
          <label className="login-label">
            Lozinka:
            <input 
               type="password" 
               value={password} 
               onChange={(e) => setPassword(e.target.value)} 
               className="login-input"
            />
          </label>
        </div>
        <button type="submit" className="login-button">Prijavi se</button>
      </form>
    </div>
  );
};

export default Login;
